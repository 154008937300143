import React, { useState } from "react";

import "./AmbassadorForm.scss";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Checkbox from "components/Checkbox";
import Button from "components/Button";
import { countries } from "components/VerticalSwiper/constants";
import SubscribeService from "services/subsribe.service";
import CircleLoader from "components/CircleLoader";
import ToasterService from "utils/toaster.util";

const AmbassadorForm = ({ formData, handleChange, setOpen }) => {
  const [loading, setLoading] = useState(false);

  const isFormValid = () => {
    return (
      formData.email &&
      formData.linkedInURL &&
      formData.twitterURL &&
      formData.instagramURL &&
      formData.country &&
      formData.message &&
      formData.termsAndConditions
    );
  };

  let isValid = !isFormValid();
  const handleSubscribeSuccess = () => setOpen(false);

  const SubscribeAsAmbassador = () => {
    if (isValid) {
      return ToasterService.showError("Fill all the fields");
    }
    SubscribeService.subscribe(formData, setLoading, handleSubscribeSuccess);
  };
  return (
    <div className="form__parent">
      {loading && <CircleLoader />}
      <div className="heading">One Last Step!</div>
      <div className="formInfo">
        Please provide a bit more information to complete your application.
      </div>
      <div className="urlHeading">
        Enter any of your social media profiles (LinkedIn, Twitter,
        Instagram, etc.)
      </div>
      <div className="urlsInputs">
        <TextField
          id="outlined-basic"
          label="Profile URL"
          variant="outlined"
          name="linkedInURL"
          value={formData.linkedInURL}
          onChange={handleChange}
        />
        <TextField
          id="outlined-basic"
          label="Profile URL"
          variant="outlined"
          name="twitterURL"
          value={formData.twitterURL}
          onChange={handleChange}
        />
        <TextField
          id="outlined-basic"
          label="Profile URL"
          variant="outlined"
          name="instagramURL"
          value={formData.instagramURL}
          onChange={handleChange}
        />
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Country</InputLabel>
          <Select
            labelId="country-select-label"
            id="country-select"
            name="country"
            value={formData.country}
            label="Country"
            onChange={handleChange}>
            {countries.map((country) => (
              <MenuItem key={country} value={country}>
                {country}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="urlHeading">
        In 100 words or less, tell us why you should be a global ambassador.
      </div>
      <div className="urlsInputs">
        <TextField
          id="outlined-basic"
          variant="outlined"
          multiline={true}
          rows={4}
          name="message"
          value={formData.message}
          onChange={handleChange}
        />
      </div>
      <div className="checkbox__parent">
        <Checkbox
          checked={formData.termsAndConditions}
          onChange={handleChange}
          name="termsAndConditions"
        />
        <div className="checkbox__text">
          Please accept the Everyone Ambassador{" "}
          <span> terms and conditions</span>
        </div>
      </div>
      <div className="submitButton">
        <Button onClick={SubscribeAsAmbassador}>Submit Application</Button>
      </div>
      <div className="urlHeading">
        NOTE <br />
        There are limited ambassador spaces per country, so apply soon to
        secure your spot!
      </div>
    </div>
  );
};

export default AmbassadorForm;
