import Cross from "assets/svg/Cross";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import CircleLoader from "components/CircleLoader";
import {
  calculateCountdown,
  defaultOptions,
  validateEmail,
} from "pages/LandingPage/constant";
import React, { useEffect, useRef, useState } from "react";
import Lottie from "react-lottie";
import SubscribeService from "services/subsribe.service";
import ToasterService from "utils/toaster.util";

const BetaUser = ({
  formData,
  handleChange,
  setOpen,
  isChecked,
  handleCheckboxChange,
}) => {
  const [countdown, setCountdown] = React.useState(calculateCountdown());
  const [loading, setLoading] = useState(false);

  const backgroundParentRef = useRef(null);

  useEffect(() => {
    backgroundParentRef.current = document.querySelector(".backgroundParent");
  }, []);

  const scrollToTop = () => {
    backgroundParentRef.current.scrollTo({
      top: 0,
    });
  };

  const SubscribeAsAmbassador = () => {
    SubscribeService.subscribe({ email: formData.email }, setLoading, setOpen);
  };
  let validEmail = validateEmail(formData.email);

  useEffect(() => {
    setCountdown(calculateCountdown());
    const timer = setInterval(() => {
      setCountdown(calculateCountdown());
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  return (
    <div className="betaParent">
      <div className="cross" onClick={scrollToTop}>
        <Cross />
      </div>
      <div className="upper__content">
        {loading && <CircleLoader />}

        <div className="text__parent">
          <h1 className="heading">Become a Beta User or Ambassador</h1>
        </div>

        <div className="form">
          <div className="input_parent">
            <input
              placeholder="Enter your Email"
              type="text"
              className="text__input"
              autoComplete="off"
              value={formData.email}
              name="email"
              onChange={handleChange}
            />
            <Button
              onClick={SubscribeAsAmbassador}
              disabled={!formData.email || validEmail}
            >
              Join Beta
            </Button>
          </div>
          <div className="checkbox__parent">
            <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
            <div className="checkbox__text">
              I want to be an Ambassador. <span>Learn More</span>
            </div>
          </div>
        </div>
        {/* <div className="timer">
          <div className="days">
            <div className="count">{countdown.days}</div>
            <div className="count_name">Days</div>
          </div>
          <div className="colon">:</div>
          <div className="days">
            <div className="count">{countdown.hours}</div>
            <div className="count_name">Hours</div>
          </div>
          <div className="colon">:</div>

          <div className="days">
            <div className="count">{countdown.minutes}</div>
            <div className="count_name">Minutes</div>
          </div>
          <div className="colon">:</div>

          <div className="days">
            <div className="count">{countdown.seconds}</div>
            <div className="count_name">Seconds</div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default BetaUser;
