import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import cardImage from "assets/cardImage.png";
import "./VerticalSwiper.scss";
// import { cards } from "./constants";
import PressRelease from "./_components/PressRelease";
import BetaUser from "./_components/PressRelease/BetaUser";
import Cross from "assets/svg/Cross";

import "../../pages/LandingPage/LandingPage.scss";

import digitalExperience from "assets/digitalExperience.png";
import globalReach from "assets/globalReach.png";
import timelessOwnerShip from "assets/timelessOwnerShip.png";
import infiniteChannels from "assets/infiniteChannels.png";
import pureVisibility from "assets/pureVisibility.png";
import empowerment from "assets/empowerment.png";
import digitalLife from "assets/digitalLife.png";
import mainLogo from "assets/mainLogo.png";
import monetization from "assets/monetization.png";

const MainCard = ({ heading, info, img }) => {
  const backgroundParentRef = useRef(null);

  useEffect(() => {
    backgroundParentRef.current = document.querySelector(".backgroundParent");
  }, []);

  const scrollToTop = () => {
    backgroundParentRef.current.scrollTo({
      top: 0,
    });
  };

  return (
    <div className="card1">
      <div className="heading">{heading}</div>
      {img && (
        <div className="cardImage">
          <img src={img} alt="" />
        </div>
      )}
      <div className="cardInfo">{info}</div>
      <div className="cross" onClick={scrollToTop}>
        <Cross />
      </div>
    </div>
  );
};

const VerticalSwiper = ({
  formData,
  handleChange,
  setOpen,
  isChecked,
  handleCheckboxChange,
}) => {
  const cards = [
    {
      card: (
        <MainCard
          heading={"Healthier Digital Experience"}
          info={"See the whole world with chronological global posting"}
          img={digitalExperience}
        />
      ),
    },
    {
      card: (
        <MainCard
          heading={"Global Reach"}
          info={
            "Post once, share everywhere and connect seamlessly across all platforms."
          }
          img={globalReach}
        />
      ),
    },
    {
      card: (
        <MainCard
          heading={"Timeless Ownership"}
          info={
            "Every post is issued a sequentially numbered channel number and becomes permanently yours."
          }
          img={timelessOwnerShip}
        />
      ),
    },
    {
      card: (
        <MainCard
          heading={"Infinite Channels"}
          info={
            "Organize your digital world by create unique channels for every activity"
          }
          img={infiniteChannels}
        />
      ),
    },
    {
      card: (
        <MainCard
          heading={"Pure Visibility"}
          info={"Be seen by the world with global posting"}
          img={pureVisibility}
        />
      ),
    },
    {
      card: (
        <MainCard
          heading={"Effortless Monetization"}
          info={"Earn from your content and monetize with no fees"}
          img={monetization}
        />
      ),
    },
    {
      card: (
        <MainCard
          heading={"Creative Empowerment"}
          info={"Access Powerful Premium Tools, 100% Free"}
          img={empowerment}
        />
      ),
    },
    {
      card: (
        <MainCard
          heading={"Simplified Digital Life"}
          info={"Your all-in-one creation space"}
          img={digitalLife}
        />
      ),
    },
    {
      card: (
        <MainCard
          heading={"Make History With Us"}
          info={
            "Join our beta before the crowd and receive an earlier, exclusive first global channel number for free, for life."
          }
          // img={mainLogo}
        />
      ),
    },
    { card: <PressRelease /> },
    {
      card: (
        <BetaUser
          formData={formData}
          handleChange={handleChange}
          setOpen={setOpen}
          isChecked={isChecked}
          handleCheckboxChange={handleCheckboxChange}
        />
      ),
    },
  ];

  return (
    <div className="slick__container">
      {cards?.map((data, i) => (
        <React.Fragment key={i}>{data.card}</React.Fragment>
      ))}
    </div>
  );
};

export default VerticalSwiper;
