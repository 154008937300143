import React, { useEffect, useRef, useState } from "react";
import Lottie from "react-lottie";
import "./LandingPage.scss";
import { calculateCountdown, landingData, logoLottieOptions } from "./constant";
import fullLogo from "../../assets/fullLogo.json";
import background from "../../assets/Background.jpg";
// import mobile_background from "../../assets/mobile_version.json";
import mobile_background from "../../assets/mobile_bg.png";
import globe from "../../assets/old-globe.png";
import globeShadow from "../../assets/globe-shadow.png";
import Button from "components/Button";
import MiniLogo from "assets/svg/MiniLogo";
import VerticalSwiper from "components/VerticalSwiper";
import Modal from "components/Modal";
import AmbassadorForm from "components/AmbassadorForm";
import SubscribeService from "services/subsribe.service";
import CircleLoader from "components/CircleLoader";
import { validateEmail } from "./constant/index";
import ToasterService from "utils/toaster.util";
// import PublicNetwork from "components/PublicNetwork";

const LandingPage = () => {
  const [countdown, setCountdown] = React.useState(calculateCountdown());
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    linkedInURL: "",
    twitterURL: "",
    instagramURL: "",
    country: "",
    message: "",
    termsAndConditions: false,
  });
  let validEmail = validateEmail(formData.email);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (!open) {
      setIsChecked(false);
    }
  }, [open]);

  const handleCheckboxChange = () => {
    if (!formData.email || validEmail) {
      ToasterService.showError("Enter your valid Email");
      setIsChecked(false);
    } else {
      setOpen(true);
      setIsChecked(true);
    }
  };

  useEffect(() => {
    setCountdown(calculateCountdown());
    const timer = setInterval(() => {
      setCountdown(calculateCountdown());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  // const handleScroll = (event) => {
  //   const backgroundParent = document.querySelector(".backgroundParent");
  //   if (
  //     event.key === "ArrowUp" ||
  //     (event.type === "click" &&
  //       event.currentTarget.classList.contains("up-arrow"))
  //   ) {
  //     backgroundParent.scrollBy({ top: -700, behavior: "smooth" });
  //   } else if (
  //     event.key === "ArrowDown" ||
  //     (event.type === "click" &&
  //       event.currentTarget.classList.contains("down-arrow"))
  //   ) {
  //     backgroundParent.scrollBy({ top: 700, behavior: "smooth" });
  //   }
  // };

  useEffect(() => {
    window.addEventListener("keydown", handleScroll);
    return () => {
      window.removeEventListener("keydown", handleScroll);
    };
  }, []);

  const SubscribeAsAmbassador = () => {
    SubscribeService.subscribe({ email: formData.email }, setLoading, setOpen);
  };

  const [scrollY, setScrollY] = useState(0);
  const backgroundParentRef = useRef(null);

  const handleScroll = () => {
    if (backgroundParentRef.current) {
      setScrollY(backgroundParentRef.current.scrollTop);
    }
  };

  useEffect(() => {
    const backgroundParent = backgroundParentRef.current;
    if (backgroundParent) {
      backgroundParent.addEventListener("scroll", handleScroll);

      return () => {
        backgroundParent.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * landingData.length);
    setCurrentIndex(randomIndex);
  }, []);

  return (
    <div className="backgroundParent relative p-2" ref={backgroundParentRef}>
      <div className="lottie-bg">
        <img className="bg-img" src={background} alt="mobile bg" />
        <img className="bg-img" src={background} alt="mobile bg" />
        ``
        {/* <img src={globe} alt="" className="globe-3d" />{" "} */}
      </div>
      <img
        style={{ width: "2000px", height: "2000px" }}
        className="fixed -bottom-[1700px] -right-[800px]  lg:-bottom-[1400px] lg:-right-[900px] z-20 max-w-none"
        src={globe}
        alt=""
      />{" "}
      <img
        style={{ width: "2000px", height: "2000px" }}
        className="fixed -bottom-[1200px] -right-[250px] md:-bottom-[1000px] md:-right-[600px] z-10 max-w-none"
        src={globeShadow}
        alt=""
      />
      {/* <div className="lottie-bg-mobile">
        <img className="bg-img" src={mobile_background} alt="mobile bg" />
        <img src={globe} alt="" className="globe-3d" />
      </div> */}
      <div className="mainLanding">
        <div className="upperPortion">
          {loading && <CircleLoader />}

          <div
            // className="upper__content"
            className={`upper__content ${scrollY > 400 ? "fade-out" : ""}`}
          >
            <div className="lottie-animation">
              <Lottie options={logoLottieOptions(fullLogo)} />
            </div>

            <div className="text__parent">
              <div className="green-shadow max-2xl:hidden backdrop-blur-lg self-end relative text-xl space-y-3 w-[400px] p-6 pt-3 text-white rounded-2xl rounded-br-none border border-green-500 shadow-green-500 z-50">
                <img
                  className="w-14 absolute -top-8 -right-4"
                  src="images/card/g2.png"
                  alt=""
                />
                <h1 className="text-2xl font-bold">Early Posting Access</h1>
                <p>
                  Be among the first to post and secure one of the earliest
                  channel numbers available, right after our professional
                  streaming services.
                </p>
              </div>
              <div>
                <h1 className="heading whitespace-nowrap">
                  Become a Pioneer of
                  <br />
                  the Digital Mainstreet{" "}
                </h1>
                <p className="info py-2">
                  Join our exclusive Ambassador Program. Shape the future of
                  digital innovation, connect with a global network, and earn
                  the prestigious Collectible Pioneer-1 NFT Badge on your
                  profile that comes with lifetime unique benefits and rewards.
                </p>
              </div>
              <div className="green-shadow max-2xl:hidden backdrop-blur-lg self-start relative text-xl space-y-3 w-[400px] p-6 pt-3 text-white rounded-2xl rounded-bl-none border border-green-500 shadow-green-500 z-50">
                <img
                  className="w-10 absolute -top-16 -left-10"
                  src="images/card/g1.png"
                  alt=""
                />
                <h1 className="text-2xl font-bold">Online Perks for Life</h1>
                <p>
                  Enjoy lifelong benefits including priority support, exclusive
                  content, and advanced feature access.
                </p>
              </div>
            </div>

            <div className="form">
              <div className="input_parent ">
                <input
                  placeholder="Enter your Email"
                  type="text"
                  className="text__input"
                  autoComplete="off"
                  value={formData.email}
                  name="email"
                  onChange={handleChange}
                />
                <Button
                  onClick={SubscribeAsAmbassador}
                  disabled={!formData.email || validEmail}
                >
                  <p className="sm:block hidden">Join as Ambassador</p>
                  <p className="max-sm:block hidden">Join Beta</p>
                </Button>
              </div>
              {/* <div className="checkbox__parent">
                <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
                <div className="checkbox__text">
                  I want to be an Ambassador. <span>Learn More</span>
                </div>
              </div> */}
            </div>
            <div className="hide-scroll max-2xl:flex hidden gap-4 p-5 pt-10 w-full overflow-x-auto overflow-y-hidden">
              <div className="green-shadow h-[170px] backdrop-blur-lg self-start relative text-sm md:text-xl space-y-3 w-[250px] md:w-[400px] p-6 pt-3 text-white rounded-2xl rounded-bl-none border border-green-500 shadow-green-500 z-50">
                <h1 className="text-lg md:text-2xl font-bold">
                  Early Posting Access
                </h1>
                <p>
                  Be among the first to post and secure one of the earliest
                  channel numbers available, right after our professional
                  streaming services.
                </p>
              </div>
              <div className="green-shadow h-[170px] backdrop-blur-lg self-start relative text-sm md:text-xl space-y-3 w-[250px] md:w-[400px] p-6 pt-3 text-white rounded-2xl rounded-bl-none border border-green-500 shadow-green-500 z-50">
                <h1 className="text-lg md:text-2xl font-bold">
                  Online Perks for Life</h1>
                <p>
                  Enjoy lifelong benefits including priority support, exclusive
                  content, and advanced feature access.
                </p>
              </div>{" "}
              <div className="green-shadow h-[170px] backdrop-blur-lg self-start relative text-sm md:text-xl space-y-3 w-[250px] md:w-[400px] p-6 pt-3 text-white rounded-2xl rounded-bl-none border border-green-500 shadow-green-500 z-50">
                <h1 className="text-lg md:text-2xl font-bold">Pioneer-1 NFT Badge</h1>
                <p>
                  Earn the exclusive Pioneer-1 NFT Badge, signifying your early
                  and influential role in our community.
                </p>
              </div>{" "}
              <div className="green-shadow h-[170px] backdrop-blur-lg self-start relative text-sm md:text-xl space-y-3 w-[250px] md:w-[400px] p-6 pt-3 text-white rounded-2xl rounded-bl-none border border-green-500 shadow-green-500 z-50">
                <h1 className="text-lg md:text-2xl font-bold">IRL Event Perks</h1>
                <p>
                  Gain access to exclusive in-person events, networking
                  opportunities, and special ambassador-only sessions.
                </p>
              </div>
            </div>
            <div className="flex items-center gap-16 max-2xl:hidden">
              <div className="green-shadow  max-2xl:hidden backdrop-blur-lg relative text-xl space-y-3 w-[400px] p-6 pt-3 text-white rounded-2xl rounded-br-none border border-green-500 shadow-green-500 z-50">
                <img
                  className="w-16 absolute -top-8 -right-4"
                  src="images/card/g3.png"
                  alt=""
                />
                <h1 className="text-2xl font-bold">Pioneer-1 NFT Badge</h1>
                <p>
                  Earn the exclusive Pioneer-1 NFT Badge, signifying your early
                  and influential role in our community.
                </p>
              </div>

              <div className="timer relative opacity-0">
                <div className="w-64 h-14 bg-gradient-to-tr from-sky-300 via-sky-400 to-blue-600 absolute top-[7px] right-[7px] -z-10 blur-xl"></div>
                <div className="days">
                  <div className="count">{countdown.days}</div>
                  <div className="count_name">Days</div>
                </div>
                <div className="colon">:</div>
                <div className="days">
                  <div className="count">{countdown.hours}</div>
                  <div className="count_name">Hours</div>
                </div>
                <div className="colon">:</div>

                <div className="days">
                  <div className="count">{countdown.minutes}</div>
                  <div className="count_name">Minutes</div>
                </div>
                <div className="colon">:</div>

                <div className="days">
                  <div className="count">{countdown.seconds}</div>
                  <div className="count_name">Seconds</div>
                </div>
              </div>

              <div className="green-shadow max-2xl:hidden backdrop-blur-lg transform translate-x-8 -translate-y-28 self-start relative text-xl space-y-3 w-[400px] p-6 pt-3 text-white rounded-2xl rounded-bl-none border border-green-500 shadow-green-500 z-50">
                <img
                  className="w-16 transform -rotate-90 absolute -top-16 -left-10"
                  src="images/card/g3.png"
                  alt=""
                />
                <h1 className="text-2xl font-bold">IRL Event Perks</h1>
                <p>
                  Gain access to exclusive in-person events, networking
                  opportunities, and special ambassador-only sessions.
                </p>
              </div>
            </div>
          </div>

          <div
            // className="bottom__content"
            className={`bottom__content pt-10 ${
              scrollY > 400 ? "fade-out" : ""
            }`}
          >
            <div className="bottom__text">
              The Everyone stream is everyone’s posts in the world,
              chronologically numbered. When you post publicly, the entire world
              can see it. Connect, share, and engage on our 100% free global
              platform. Join the Beta to be among the first to post and receive
              exclusive access, priority support, and special recognition as
              an early adopter.
              <div className="flex gap-3 items-center justify-center pt-4 text-slate-100 max-md:text-sm whitespace-nowrap">
                <p>Terms</p>
                <p>Privacy Policy</p>
                <p>Cookies</p>
                <p>Copyright</p>
              </div>
            </div>
            <div className="copyRight pb-10">
              <MiniLogo />
              <div className=" text-slate-100">© 2024 Everyone, Inc.</div>
            </div>
          </div>
          {/* <div className="actionButtons">
            <div className="cursor up-arrow" onClick={handleScroll}>
              <UpArrow />
            </div>
            <div>
              <MouseIcon />
            </div>
            <div className="cursor down-arrow" onClick={handleScroll}>
              <DownArrow />
            </div>
          </div> */}
          {/* <div className="globe-container">
              <img src={globe} alt="Rotating Globe" className="globe" />
            </div> */}
        </div>

        {/* <PublicNetwork /> */}

        <VerticalSwiper
          formData={formData}
          handleChange={handleChange}
          setOpen={setOpen}
          isChecked={isChecked}
          handleCheckboxChange={handleCheckboxChange}
        />
      </div>
      <Modal setOpen={setOpen} open={open}>
        <AmbassadorForm
          formData={formData}
          handleChange={handleChange}
          setOpen={setOpen}
        />
      </Modal>
    </div>
  );
};

export default LandingPage;
