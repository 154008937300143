import "./App.css";
import LandingPage from "./pages/LandingPage";
import Toaster from "components/Toaster";
import { SnackbarProvider } from "notistack";
import ReactGA from "react-ga4";

function App() {
  ReactGA.initialize("G-TBFMDLLFSY");

  return (
    <SnackbarProvider
      maxSnack={5}
      hideIconVariant
      preventDuplicate
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      iconVariant={{
        success: "✅",
        error: "✖️",
        warning: "⚠️",
        info: "ℹ️",
      }}
    >
      <Toaster />
      <LandingPage />
    </SnackbarProvider>
  );
}

export default App;
