import React from "react";

const Cross = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.5126 2.25008L3.0127 21.75M3.01261 2.25L22.5125 21.7499"
        stroke={color || "white"}
        strokeWidth="4.0625"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Cross;
