import * as React from "react";
import Dialog from "@mui/material/Dialog";

export default function Modal({ children, setOpen, open }) {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Dialog
        sx={{ "& .MuiPaper-root": { margin: "10px" } }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        {children}
      </Dialog>
    </React.Fragment>
  );
}
