import React from "react";
import "./Checkbox.scss";

const Checkbox = ({ checked, onChange, name }) => {
  return (
    <label className="custom-checkbox">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        name={name}
      />
      <span className="custom-checkbox-inner"></span>
    </label>
  );
};

export default Checkbox;
