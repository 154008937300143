export const bgLottieOptions = (fullLogo) => ({
  loop: true,
  autoplay: true,
  animationData: fullLogo,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
});

export const logoLottieOptions = (fullLogo) => ({
  loop: false,
  autoplay: true,
  animationData: fullLogo,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
});

export const calculateCountdown = () => {
  const countdownDate = new Date("2025-08-01T00:00:00").getTime();
  const now = new Date().getTime();
  const distance = countdownDate - now;

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
};

export const validateEmail = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)
    ? "Invalid email address"
    : undefined;

export const landingData = [
  {
    heading: "Broadcast Everywhere: Centralized and Decentralized",
    info: "With one click we bridge your post to all your favorite social media platforms and decentralized protocols.",
  },
  {
    heading: "Instant Universal Posting",
    info: "With Everyone, share your content instantly across centralized and decentralized networks, ensuring unparalleled reach and immediate visibility worldwide.",
  },
  {
    heading: "One Click, Every Network: The Ultimate Posting Power",
    info: "When you post on the Everyone stream, you post everywhere at once.",
  },
  {
    heading: "The World's Infinite Channel Stream is About to Launch",
    info: "Let's make history as we unveil the world's most fair communication platform that places every global post chronologically for everyone to see.",
  },
  {
    heading: "The World's Infinitely Sequential Channel Stream",
    info: "Join a pioneering platform that facilitates direct global communication, fair chronological content distribution, and robust privacy, at no cost.",
  },
  {
    heading: "The Global Communication Tool for Everyday Use",
    info: "Experience the seamless integration of social and productivity tools within a user-friendly framework, accessible to all at no cost.",
  },
  {
    heading: "Enhancing Global Communication for Modern Needs",
    info: "Everyone offers advanced tools and a streamlined platform for efficient, real-time global communication.",
  },
  {
    heading: "Global Connection, Simplified",
    info: "Enjoy secure and effortless communication with a user-centric design and customizable experience.",
  },
  {
    heading: "One Stream, Every Voice",
    info: "Connect with a diverse international audience on a platform that promotes authentic narratives and enhanced engagement.",
  },
  {
    heading: "Join the Unified Global Conversation, Free and Open",
    info: "Be part of an inclusive platform celebrating real-life stories and facilitating free, direct communication.",
  },
];
