import Cross from "assets/svg/Cross";
import React, { useEffect, useRef } from "react";

import logo from "../../../../assets/logo.png";

const PressRelease = () => {
  const backgroundParentRef = useRef(null);

  useEffect(() => {
    backgroundParentRef.current = document.querySelector(".backgroundParent");
  }, []);

  const scrollToTop = () => {
    backgroundParentRef.current.scrollTo({
      top: 0,
    });
  };
  return (
    <div className="pressRelease__container hide-scroll">
      <div className="cross" onClick={scrollToTop}>
        <Cross color={"black"} />
      </div>
      <div className="press__logo">
        <img src={logo} alt="" className="logo" />
        <div className="name">everyone</div>
        <div className="domain">EVERYONE.WORLD</div>
      </div>
      <div className="pressTitle">- July 24, 2024 -</div>
      <div className="heading">
        Meet Everyone: The Ultimate Global Communication Tool for Everyday Use
      </div>

      {/* <div className="socialIcons">
        <Facebook />
        <Twitter />
        <Instagram />
        <LinkedIn />
        <Telegram />
        <LInk />
      </div> */}

      <div className="pressRelease__text">
        <span>Los Angeles, California</span> – Welcome to Everyone, the
        transformative communication platform that integrates every form of
        communication into numbered channels within a single, instantly visible
        global stream. It is the ultimate one-stop hub for disseminating any
        type of information to all the world's communication networks
        simultaneously and without charge. <p /> At the heart of Everyone is a
        sequentially numbered blockchain that starts at Channel 1. Each new post
        receives a subsequent universal channel number, making it immediately
        visible worldwide. Channel numbers can be used in several ways including
        as phone numbers that protect your actual phone number or as television
        channel numbers accessible on your computer or TV. These channels aren't
        just digital addresses; they are dynamic meeting points blending phone
        calls, emails, texts, group chats, videos, audio, documents, websites,
        sharing, and much more into a single, unified experience.
        <p /> Built with a commitment to inclusivity and accessibility, Everyone
        ensures that every voice is heard across the globe. Users are empowered
        to share using a suite of sophisticated tools, and to broadcast messages
        across both social media and decentralized networks at no cost. We
        gather contributions from a diverse array of networks into a single
        unified stream, ensuring that when you post on Everyone, your message is
        accessible to all at everyone.world and simultaneously across other
        global networks. The stream promotes seamless and equitable global
        connection, true to our vision of a world where every individual has the
        opportunity to send worldwide communications freely, fairly, and without
        cost.
        <p />
        <span>Everyone Introduces Everything Channels</span>
        <p />
        Design your own digital realm within Everything Channels, versatile
        spaces where you can seamlessly integrate a wide array of content types,
        from threads and photos to videos, websites, live streams, meetings, and
        chat rooms. Similar to how a website serves as a canvas for your
        creativity, Everything Channels offer a comparable level of
        customization and control. Here, you can enrich your channels with
        everything from straightforward posts to complex code, tailoring each
        channel to reflect your unique vision and needs.
        <p />
        <span>Everyone Broadcasts Everywhere at Once</span>
        <p />
        Post to all centralized the decentralized networks at once. Everyone
        aims to unite the world on a single stream, offering an experience you
        can't find anywhere else on the internet. The Everywhere-Bridge
        seamlessly connects decentralized and centralized networks, letting you
        broadcast your posts to Everyone's global stream, your social media
        accounts, and all decentralized platforms, all at once. Maximize your
        reach and visibility with complete freedom.
        <p />
        <span>Access All Premium Tools for Free with Everyone</span>
        <p />
        Discover a suite of powerful premium tools, all free of charge. Design
        and edit photos like a pro with the image creator and editor, build
        websites, hold meetings, and manage your business and daily activities
        with the Everyone CRM. Streamline your work with integrated word
        processing and spreadsheets. These premium features empower users to
        create and accomplish more, without the price tag.
        <p />
        <span>Build Free Websites with Instant Global Reach</span>
        <p />
        Everyone streamlines website creation with our intuitive drag-and-drop
        editor, allowing users of all skill levels to craft beautiful,
        functional websites effortlessly. For those who seek deeper
        customization, advanced coding options are available, enabling you to
        fine-tune every aspect of your site to your exact specifications. Add a
        custom domain to any channel, transforming it into a personalized space
        that truly represents your brand or personal vision. Once ready, publish
        your site under the next available global channel number for instant
        visibility across the network. This seamless process ensures your site
        is not only tailored to your needs but also optimally positioned for
        global reach.
        <p />
        <span>Everyone Workflow and Project Management for All</span>
        <p />
        Everyone Workflow enhances your productivity through an intuitive
        platform that facilitates project management and workflow automation,
        accessible to everyone at no cost. This comprehensive tool allows teams
        to efficiently manage projects, automate tasks, and streamline daily
        operations within a unified system.
        <p />
        <p />
        With visual project boards, customizable templates, and versatile
        integration options, Everyone Workflow ensures that all members of a
        team can effectively collaborate and track progress. These features are
        designed to support efficiency, making professional-grade management
        tools freely available to every user around the world.
        <p />
        <span>Simplify Your Digital Life with an All-in-One Organizer</span>
        <p />
        Everyone fuses global broadcasting with productivity, merging personal
        and business functionalities into a seamless platform. Whether you're
        simultaneously posting across all your social channels or managing
        meetings and file storage for your business, Everyone equips you with
        the essential tools. It's the ultimate end-to-end solution for
        individuals, businesses, nonprofits, and schools. Leverage the global
        stream for product management, centralized communication, or even as a
        video channel for your local community. Dive into a world where
        productivity meets creativity and watch your digital life transform.
        <p />
        <span>Freelancers and Gig Workers Keep 100% of Earnings</span>
        <p />
        At Everyone, we empower freelancers, gig workers, and independent
        professionals by eliminating platform fees. Our comprehensive ecosystem
        supports you in posting gigs, collaborating on projects. And hiring
        top-tier talent.
        <p />
        Maximize your professional potential by leveraging our tools designed
        for seamless job management and client interaction. With Everyone, you
        gain access to a global network of professionals across diverse fields,
        enabling you to offer your services and discover new opportunities
        effortlessly. Our platform not only connects you to clients but also
        equips you with powerful features to manage contracts, schedule tasks,
        and ensure secure payments.
        <p />
        Join a community of global professionals on Everyone, where your skills
        are valued and your career can flourish without limitations.
        <p />
        <span>Full Content Ownership with Blockchain Security</span>
        <p />
        On Everyone, users retain full ownership of their content. Each post is
        recorded on the blockchain, ensuring traceable ownership and giving
        users full control over how their content is used and monetized.
        Everyone is committed to safeguarding user privacy and never selling
        user data. Information is either end-to-end encrypted, so we never see
        it, or it is protected and used solely to enhance the user experience
        without compromising privacy.
        <p />
        Web3 and NFTs enable all types of businesses to monetize their content
        by directly selling digital assets, earning royalties through smart
        contracts, and offering exclusive experiences to their audience. This
        approach empowers creators and businesses to maximize their earnings and
        build stronger connections with their customers, without middleman fees.
        <p />
        <span>Everyone DAO: Community-Driven Decentralized Governance</span>
        <p />
        Get ready to shape the future of Everyone. With the Everyone
        Decentralized Autonomous Organization (DAO), users are at the helm.
        Every time you create a channel, you receive voting tokens used for DAO
        governance decisions. This means the platform evolves based on what you,
        the community, want. It’s your voice, your choices, and your vision
        steering the ship. Dive in and help direct the future of Everyone,
        ensuring it grows and changes to meet the community's needs and desires.
        <p />
        <span>Uncompromised Privacy with End-to-End Encryption</span>
        <p />
        Privacy is paramount, and with end-to-end encryption, your messages,
        videos, and files are secured from sender to recipient. Only you and
        your intended recipient can read or view your content, no one else, not
        even us. By keeping your communication strictly private, we ensure your
        conversations stay just between you and the people you trust. Enjoy
        peace of mind knowing your data is fully protected.
        <p />
        <span>Become a Beta User or Ambassador in Our Global Community </span>
        <p />
        Step into the future of global communication with Everyone. Secure early
        channel numbers and exclusive access as a beta user or ambassador. Our
        Global Stream ensures equal visibility for every post, fostering an
        inclusive community. Engage, uplift, and support fellow members with
        micro-giving, starting from just $0.01, with no platform charges,
        ensuring creators receive every cent.
        <p />
        Everyone champions global dialogue, creativity, unity, and mutual
        respect through a single stream structure. Experience free premium tools
        for individuals and businesses. Enjoy exclusive perks, including the
        Pioneer-1 NFT collectible badge. Limited ambassador spots are available
        per country.
        <p />
        <span>About the Beta Launch </span>
        <p />
        Everyone is freemium and global. Worldwide Beta posting starts on August
        27, 2024. Early adopters, including ambassadors and beta users, will
        have the advantage of securing the first available channel numbers.
        These early channel numbers offer prime virtual spaces for future
        content. Ambassadors will be the first to post, with regional
        availability ensuring unique opportunities. Perks include the exclusive
        Pioneer-1 NFT Profile Badge with lifetime benefits, discounts, and free
        gifts. To sign up as a beta user, visit everyone.world. Join a global
        network, make history, and secure your early channel number and
        collectible Pioneer-1 NFT badge today.
        <p />
        <span>About Everyone</span>
        <p />
        Everyone is designed to unite the world in a single, fair global stream.
        Built on principles of inclusivity, transparency, and empowerment,
        Everyone offers a suite of free tools to seamlessly connect individuals,
        businesses, and communities. Early adopters can secure early channel
        numbers, prime virtual spaces for future content. Visit
        <a href="/"> everyone.world.</a>
        to sign up and be among the first to make history with the inaugural
        posts for the world and generations to come.
        <p />
        <span>Press Contact</span>
        <p />
        Everyone Media line <br />
        <a href="/"> media@everyone.world</a>
      </div>
    </div>
  );
};

export default PressRelease;
